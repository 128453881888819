.wrapper {
  display: grid;
  grid-template-areas:
    "label"
    "input";
  align-items: start;
}

.input {
  grid-area: input;

  > input {
    color: var(--text-color-high);
    width: 100%;
    padding: .8rem 1rem;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    appearance: none;
    font-size: 1rem;
    border: 2px solid var(--border-color);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    transition-property: border-color;
    transition-duration: var(--transition-timing);
    z-index: 1;

    &:enabled:hover {
      border-color: var(--text-color-disabled);
    }

    &:focus {
      border-color: var(--accent-color-primary);
    }

    &:focus:invalid {
      border-color: var(--color-state-error-medium);
    }

    &:disabled + .label {
      color: var(--text-color-disabled);
    }

    &:focus + .label {
      color: var(--accent-color-primary);
      transform: translateY(-50%);
      font-size: .8rem;
    }

    &:focus:invalid + .label {
      color: var(--color-state-error-medium);
    }
  }

  &:has(input:focus) ~ .label {
    color: var(--accent-color-primary);
  }
}

.input > div {
  --PhoneInputCountrySelect-marginRight: 0;
  column-gap: .5rem;
  transition-property: border-color, color;
  transition-duration: var(--transition-timing);

  border: {
    width: 2px;
    color: var(--border-color);
    style: solid;
  }

  border-radius: var(--border-radius) 0 0 var(--border-radius);
  padding: .8rem 1rem;
  margin-right: -2px;

  &:hover {
    border-color: var(--text-color-disabled);
    z-index: 2;
  }
}

.label {
  grid-area: label;
  align-self: center;
  justify-self: start;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
  z-index: 2;
}

.error {
  .input > input {
    border-color: var(--color-state-error-medium);
  }

  .label {
    color: var(--color-state-error-medium);
  }
}

.caption {
  padding: .2rem .5rem;
  color: var(--color-state-error-medium);
  font-size: smaller;
  word-break: break-word;
}
