.loader {
  animation: spin 1s infinite linear;
  border: 2px solid var(--border-color);
  border-left: 2px solid var(--text-color-high);
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
