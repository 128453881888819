:where(html) {
  --ease-3: cubic-bezier(.25, 0, .3, 1);
  --transition-timing: .2s;

  --animation-fade-in: fade-in var(--transition-timing) var(--ease-3);
  --animation-fade-out: fade-out var(--transition-timing) var(--ease-3);
  --animation-slide-down: slide-down var(--transition-timing) var(--ease-3);
  --animation-slide-right: slide-right var(--transition-timing) var(--ease-3);
  --animation-skeleton-loading: skeleton-loading 1s linear infinite alternate;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    max-height: 0;
  }
}

@keyframes slide-right{
  from {
    transform: translateX(-100%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(82, 80, 79, 0.3);
  }

  100% {
    background-color: rgba(82, 80, 79, 0.7);
  }
}
