.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.wrapper > svg {
  height: 2rem;
  object-fit: scale-down;
  fill: var(--text-color-medium);
}
