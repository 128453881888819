.wrapper {
  position: relative;
  display: grid;
  grid-template-areas:
    "label"
    "input"
    "message";
  align-items: start;
  width: 100%;
  isolation: isolate;
}

.input {
  grid-area: input;
  color: var(--text-color-high);
  width: 100%;
  height: 100%;
  padding: .8rem 2rem .8rem 1rem;
  background-color: transparent;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  min-width: 25ch;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  appearance: none;
  transition-property: border-color;
  transition-duration: var(--transition-timing);

  &:enabled:hover {
    border-color: var(--text-color-disabled);
  }

  &:enabled:hover ~ svg {
    fill: var(--text-color-high);
  }

  &:enabled:focus {
    border-color: var(--accent-color-primary);
  }

  &:is(:focus) ~ label {
    color: var(--accent-color-primary);
    font-size: .8rem;
  }

  &:is(:disabled) ~ label {
    color: var(--text-color-disabled);
  }

  &:disabled {
    color: var(--text-color-disabled);
    cursor: not-allowed;
  }

  &:is(:disabled) ~ svg {
    fill: var(--text-color-disabled);
  }

  option {
    color: initial;
    padding: 0;
  }
}

.wrapper > svg {
  grid-area: input;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 1rem;
  fill: var(--text-color-medium);
  transition: fill var(--transition-timing);
  z-index: -1;
}

.label {
  grid-area: label;
  justify-self: start;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
  align-self: center;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
}

.error {
  .input {
    border-color: var(--color-state-error-medium);
  }

  .label {
    color: var(--color-state-error-medium);
  }
}

.message {
  grid-area: message;
}
