.wrapper {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  height: 100%;
  background-color: var(--surface-0);
  overflow-y: auto;
}
