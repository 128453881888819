.large {
  --grid-item--min-width: 350px;
}

.medium {
  --grid-item--min-width: 230px;
}

.small {
  --grid-item--min-width: 150px;
}

.wrapper {
  --grid-layout-gap: 1rem;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(var(--grid-item--min-width), 100%), 1fr));
  column-gap: var(--grid-layout-gap);
  row-gap: 1rem;
  align-items: start;
  width: 100%;

  &.auto-columns {
    --grid-column-count: 1;
  }

  &.two-columns {
    --grid-column-count: 2;
  }

  &.three-columns {
    --grid-column-count: 3;
  }

  &.four-columns {
    --grid-column-count: 4;
  }
}

.left {
  justify-items: start;
}

.right {
  justify-items: end;
}
