.user-badge {
  border-radius: 10px;
  background-color: var(--surface-3);
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  box-shadow: var(--box-shadow);
}

.user-badge-content-loading {
  width: 100%;
}

.user-badge-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: .25rem;
  text-align: center;

  > * {
    word-break: break-word;
    white-space: wrap;
  }
}

.user-badge-secondary {
  font-size: 0.9rem;
  color: var(--text-color-medium);
}

.user-badge-hole {
  width: 3rem;
  height: 0.6rem;
  background-color: var(--surface-0);
  border-radius: 10px;
}

.institution-logo {
  max-height: 50px;
  width: 80%;
  height: auto;
  object-fit: scale-down;
}
