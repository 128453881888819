.input {
  display: none;

  &:checked + .label {
    color: var(--text-color-high);
    border-color: var(--accent-color-primary);
  }
}

.label {
  display: block;
  padding: .8rem 1.8rem;
  border: 2px solid var(--border-color);
  cursor: pointer;
  color: var(--text-color-medium);
  transition-property: color, border-color;
  transition-duration: var(--transition-timing);
  border-radius: var(--border-radius);

  &:hover {
    color: var(--text-color-high);
    border-color: var(--text-color-disabled);
  }
}
