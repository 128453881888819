@import "../../sass/_mixins.scss";

.wrapper {
  position: relative;

  &:focus-within {
    .container {
      border-color: var(--accent-color-primary);
    }

    .label {
      color: var(--accent-color-primary);
    }
  }
}

.container {
  display: flex;
  align-items: center;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: .8rem 1rem;
  width: fit-content;
  transition-property: border-color;
  transition-duration: var(--transition-timing);

  &:hover {
    border-color: var(--text-color-disabled);
  }
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform: translateY(50%);
  border-radius: var(--border-radius);
  color: var(--text-color-medium);
  padding: .2rem .5rem;
  margin-left: 12px;
  background-color: var(--surface-2);
  transform: translateY(-50%);
  font-size: .8rem;
}

.input {
  @include reset-input-field;
  text-align: center;
}

.input-header {
  width: 2rem;
}

.input-four-digit {
  width: 4rem;
}

.input-three-digit {
  width: 3rem;
}
