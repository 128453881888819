@import '@agora-care/ui-components/var';

:root {
  --button-height: 40px;

  --surface-hue: 0;
  --surface-saturation: 0%;

  --scroll-bar-color: hsl(var(--surface-hue), var(--surface-saturation), 30%);
  --scroll-bar-color-hover: hsl(var(--surface-hue), var(--surface-saturation), 40%);

  --color-state-processing: rgba(255, 255, 255, 0.6);
  --bg-color-state-processing: rgba(255, 255, 255, 0.2);

  --navigation-width: 230px;

  --title-font-size: 1.5rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/font/Roboto/Roboto-Regular.ttf') format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/font/Roboto/Roboto-Regular.ttf') format("truetype");
  font-weight: bold;
}

html {
  position: relative;
  background-color: var(--surface-0);
}

html,
body {
  font-size: 16px;
}

body {
  position: relative;
  height: 100vh;
  height: 100dvh;
  font-family: 'Roboto', sans-serif;
  color: var(--text-color-high);
  background-image: url(./assets/image/agora-background.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--surface-1);
}

#root {
  height: 100%;
}

.agora-input input,
.agora-input select {
  padding: 0.25em 0.5em;
  font-size: 1em;
  text-decoration: none;
}

:where(h2) {
  font-size: 1.2rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-color-hover);
}
