.wrapper {
  display: grid;
  grid-template-areas:
    "label"
    "input";
  align-items: start;
  row-gap: .5rem;
}

.label {
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
  pointer-events: none;
  color: var(--text-color-medium);
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: center;
}

.container {
  flex-grow: 1;
}
