.wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: min(100%, 900px);
}

.search-by-pid {
  margin-bottom: 1rem;
}

.confirmation-popup {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.checkbox-paperless {
  align-self: center;
  transform: translateY(50%);
}
