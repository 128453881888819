.wrapper {
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: var(--border-radius);
  animation: var(--animation-fade-in);

  &.error {
    color: var(--color-state-error-medium);
    background-color: var(--color-state-error-dark);
  }

  &.success {
    color: var(--color-state-success-medium);
    background-color: var(--color-state-success-dark);
  }

  &.warning {
    color: var(--color-state-warning-medium);
    background-color: var(--color-state-warning-dark);
  }

  &.info {
    color: var(--color-state-processing);
    background-color: var(--bg-color-state-processing);
  }
}
