@import '../../sass/breakpoints.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  @include breakpoint-down('sm') {
    flex-direction: column-reverse;
  }
}
