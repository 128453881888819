.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: var(--title-font-size);
  font-weight: bold;
}
