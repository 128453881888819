.wrapper {
  display: grid;
  grid-template-areas:
    "label desactivatable"
    "input input"
    "message message";
  align-items: start;
}

.input {
  grid-area: input;
  color: var(--text-color-high);
  width: 100%;
  padding: .8rem 1rem;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  appearance: none;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  transition-property: border-color;
  transition-duration: var(--transition-timing);

  &:enabled:hover {
    border-color: var(--text-color-disabled);
  }

  &:focus {
    border-color: var(--accent-color-primary);
  }

  &:focus:invalid {
    border-color: var(--color-state-error-medium);
  }

  &:disabled {
    color: var(--text-color-disabled);
  }

  &:disabled + .label {
    color: var(--text-color-disabled);
  }

  &:focus + .label {
    color: var(--accent-color-primary);
  }

  &:focus:invalid + .label {
    color: var(--color-state-error-medium);
  }
}

.input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: none;
    color: var(--text-color-medium);
    filter: invert(.8);
    transition: filter var(--transition-timing);

    &:hover {
      filter: invert(1);
    }
  }

  &::-webkit-calendar-picker-indicator:hover {
    background-color: var(--main-color-4);
  }
}

.on-top {
  align-self: center;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
}

.label {
  @extend .on-top;
  grid-area: label;
  justify-self: start;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
}

.desactivatable {
  @extend .on-top;
  grid-area: desactivatable;
  justify-self: end;
  margin-right: 12px;
}

.error {
  .input {
    border-color: var(--color-state-error-medium);
  }

  .label {
    color: var(--color-state-error-medium);
  }
}

.message {
  grid-area: message;
}

.loader {
  grid-area: input / desactivatable;
  align-self: center;
  justify-self: end;
  margin-right: 1rem;
}
