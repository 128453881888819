@import "../../sass/breakpoints.scss";

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "input button"
    "error error";
  align-items: end;

  @include breakpoint-down('sm') {
    grid-template-columns: 1fr auto auto;
    grid-template-areas:
      "input"
      "error"
      "button";
    row-gap: .5rem;
  }
}

.input {
  grid-area: input;

  > input {
    @include breakpoint-up('sm') {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }
  }
}

.button {
  grid-area: button;
  border-left: none;

  padding: {
    top: .7rem;
    bottom: .8rem;
  }

  border-width: 2px;

  @include breakpoint-up('sm') {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }
}

.error {
  grid-area: error;
}
