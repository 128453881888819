.list-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: .5rem;
}

.bottom-link {
  border-top: 1px solid var(--border-color);
  padding-top: .5rem;
  margin-top: auto;
}
