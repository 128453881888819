@import '../../sass/breakpoints';

.wrapper {
  display: grid;
  grid-template-columns: 230px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar toolBar"
    "sidebar main";
  margin-right: auto;
  margin-left: auto;
  background-color: var(--surface-1);
  height: 100%;
  max-width: 1550px;

  @include breakpoint-down('sm') {
    grid-template-columns: 1fr;
    grid-template-areas:
      "sidebar"
      "main";
  }
}

.appbar {
  grid-area: toolBar;
  position: sticky;
  top: 0;
}

.sidebar {
  grid-area: sidebar;
  border-bottom: 1px solid var(--border-color);
}

.main {
  grid-area: main;
  padding: 2rem;
  height: 100%;
  overflow-y: auto;
}
