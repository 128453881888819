.iframe-wrapper {
  border: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .container-password-iframe {
    border: 0;
    width: 100%;
    min-height: 350px;
  }
}
