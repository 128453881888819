@mixin reset-input-field {
  margin: 0;
  border: none;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  appearance: none;
  font: inherit;
  color: inherit;
  opacity: 1;
}

@mixin reset-button {
  @include reset-input-field;
  cursor: pointer;
}

@mixin loading-box($height: 2rem) {
  height: $height;
  border-radius: var(--border-radius);
  animation: var(--animation-skeleton-loading);
}
