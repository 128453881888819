.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--main-color-rgb), 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.content {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
  row-gap: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--surface-2);
  inline-size: min(90vw, 550px);
  max-block-size: 90vh;
  overflow-y: auto;
  box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
}

.alert .title {
  text-transform: none;
  text-align: center;
}

.popup-header {
  grid-area: header;
  display: grid;
  align-items: center;
  row-gap: 1.5rem;
  column-gap: 1rem;
  grid-template-columns: 1fr auto;
  padding: 1rem 1.5rem;
  background-color: var(--surface-3);
  box-shadow: 0px 0px 50px 2px rgba(0, 0, 0, 0.1);
}

.popup-alert .popup-header {
  box-shadow: none;
  background-color: transparent;
  padding: 1.5rem;
}

.popup-header.header-with-icon {
  grid-template-columns: auto 1fr auto;
}

.title {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.close-icon {
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
  background-color: transparent;
  user-select: none;
  border-radius: var(--border-radius);
  fill: var(--text-color-medium);
  cursor: pointer;
  transition: background-color .3s;
  padding: .5rem;

  :hover {
    background-color: var(--surface-0);
  }

  svg {
    transform: rotate(45deg);
    height: 1.2rem;
  }
}

.main {
  grid-area: content;
  overflow-y: auto;
  padding: 1.5rem;
}
