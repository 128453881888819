.wrapper {
  --grid-column-count: 2;
  --grid-layout-gap: 1rem;
  --grid-item--min-width: 480px;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(var(--grid-item--min-width), 100%), 1fr));
  row-gap: 3rem;
  column-gap: var(--grid-layout-gap);
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
