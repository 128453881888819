.wrapper {
  display: grid;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: space-between;
  row-gap: .25rem;
  column-gap: 2rem;
  transition: background-color var(--transition-timing);
  animation: var(--animation-fade-in);

  > * {
    word-break: break-word;
    white-space: wrap;
  }
}

.value {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.secondary {
  font-size: 0.9rem;
  color: var(--text-color-medium);
}
