.wrapper {
  position: relative;
  display: grid;
  row-gap: 0.25rem;
  cursor: pointer;
}

.user-infos {
  display: grid;
  grid-template-areas:
    "arrow name"
    "arrow id ";
    column-gap: 1rem;
  row-gap: 0.25rem;
  align-items: center;
  font-size: .8rem;
  text-align: right;

  > span {
    display: block;
  }
}

.arrow {
  grid-area: arrow;
  display: flex;
  align-items: center;
  fill: var(--text-color-medium);
  transform: rotate(180deg);
  padding: .5rem;
  background-color: var(--surface-1);
  border-radius: 100%;

  > svg {
    height: .8rem;
  }
}

.name {
  grid-area: name;
}

.agora-id {
  grid-area: id;
  color: var(--text-color-medium);
}

.menu {
  position: absolute;
  right: 0;
  grid-row: 2;
  align-self: start;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-top: .5rem;
  padding: .5rem;
  border-radius: var(--border-radius);
  background-color: var(--surface-2);
  box-shadow: var(--box-shadow);
  animation: var(--animation-fade-in);

  &[aria-expanded="false"] {
    display: none;
  }

  > * {
    text-wrap: nowrap;
    white-space: nowrap;
  }
}
