@import "../../sass/breakpoints.scss";

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    "label desactivatable ."
    "input input button"
    "message message message"
    "items items items";
  align-items: start;

  @include breakpoint-down('sm') {
    grid-template-columns: auto auto;
    grid-template-areas:
      "label desactivatable"
      "input input"
      "message message"
      "button button"
      "items items";
  }
}

.input {
  grid-area: input;
  color: var(--text-color-high);
  width: 100%;
  padding: .8rem 1rem;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  appearance: none;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  transition-property: border-color;
  transition-duration: var(--transition-timing);
  border-radius: var(--border-radius);

  &:enabled:hover {
    border-color: var(--text-color-disabled);
  }

  &:focus {
    border-color: var(--accent-color-primary);
  }

  &:focus:invalid {
    border-color: var(--color-state-error-medium);
  }

  &:disabled {
    color: var(--text-color-disabled);
  }

  &:disabled + .label {
    color: var(--text-color-disabled);
  }

  &:focus + .label {
    color: var(--accent-color-primary);
  }

  &:focus:invalid + .label {
    color: var(--color-state-error-medium);
  }

  @include breakpoint-up('sm') {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }
}

.on-top {
  align-self: center;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
}

.label {
  @extend .on-top;
  grid-area: label;
  justify-self: start;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
}

.desactivatable {
  @extend .on-top;
  grid-area: desactivatable;
  justify-self: end;
  margin-right: 12px;
}

.error {
  .input {
    border-color: var(--color-state-error-medium);
  }

  .label {
    color: var(--color-state-error-medium);
  }
}

.message {
  grid-area: message;
}

.button {
  grid-area: button;
  align-self: center;
  height: 100%;
  margin-top: .5rem;

  @include breakpoint-up('sm') {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    border-width: 2px 2px 2px 0;
    margin-top: 0;
  }
}

.items {
  grid-area: items;
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
  margin-top: .5rem;
}
