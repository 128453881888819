.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
  align-items: end;
  column-gap: 1rem;
  row-gap: .5rem;
}

.date-field {
  padding-bottom: 1rem;
}
