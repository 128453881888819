.wrapper {
  position: relative;
  display: grid;
}

.loader {
  position: absolute;
  grid-row: 1/1;
  bottom: 0;
  right: 0;
  padding: .8rem 1rem;
}
