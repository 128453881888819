.wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: min(100%, 900px);
}

.form-user-request > *:not(:last-child) {
  margin-bottom: 1rem;
}

.form-user-request-error {
  padding: 0;
}
