.wrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 1rem;
  background-color: var(--surface-0);
  box-shadow: var(--box-shadow);
}

.logos {
  display: flex;
  align-items: center;
  column-gap: .5rem;

  > :is(.agora-logo, .institution-logo) {
    height: 2rem;
    width: auto;
    max-width: 4rem;
  }
}

.x-mark {
  height: 1rem;
  fill: var(--text-color-disabled);
  transform: rotate(45deg);
}
