.wrapper {
  display: grid;
  grid-template-areas:
    "label"
    "input"
    "message";
  align-items: start;
}

.container {
  grid-area: input;
  color: var(--text-color-high);
  width: 100%;

  padding: {
    top: .5rem;
    bottom: .5rem;
    left: .5rem;
  }

  background-color: transparent;
  text-decoration: none;
  outline: none;
  appearance: none;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  transition-property: border-color;
  transition-duration: var(--transition-timing);

  &:hover {
    border-color: var(--text-color-disabled);

    .dropdown-indicator > svg {
      fill: var(--text-color-high);
    }
  }

  &:focus-within {
    border-color: var(--accent-color-primary);
  }

  .control {
    min-height: 0;
  }
}

.indicator {
  padding: .5rem;
  cursor: pointer;
}

.clear-indicator {
  @extend .indicator;

  > svg {
    fill: var(--text-color-medium);
    transition: fill var(--transition-timing);
  }

  &:hover > svg {
    fill: var(--color-state-error-medium);
  }
}

.dropdown-indicator {
  @extend .indicator;

  > svg {
    fill: var(--text-color-medium);
    transition: fill var(--transition-timing);
  }
}

.indicator-separator {
  background-color: var(--border-color);
}

.control {
  background-color: transparent;
  cursor: pointer;
}

.value-container {
  gap: .5rem;
  align-items: center;
  cursor: pointer;
}

.multi-value {
  border-radius: var(--border-radius);
  background-color: var(--surface-4);
  border: 1px solid var(--border-color);
  padding: .25rem .5rem;
  column-gap: .5rem;
}

.multi-value-remove {
  cursor: pointer;

  > svg {
    fill: var(--text-color-medium);
    transition: fill var(--transition-timing);
  }

  &:hover > svg {
    fill: var(--color-state-error-medium);
  }
}

.menu {
  top: calc(100% + .5rem);
  left: 0;
  background-color: var(--surface-1);
  border-radius: var(--border-radius);
  border: 2px solid var(--border-color);
  padding: var(--border-radius) 0;
  font-size: 1rem;
  color: var(--text-color-high);
  outline: none;
  appearance: none;
  transition-property: border-color;
  transition-duration: var(--transition-timing);
  box-shadow: var(--box-shadow);
}

.option {
  padding: 1rem;

  &[aria-disabled="false"]:hover {
    background-color: var(--surface-4);
  }

  &[aria-disabled="true"] {
    color: var(--text-color-disabled);
  }
}

.label {
  grid-area: label;
  justify-self: start;
  align-self: center;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
}
