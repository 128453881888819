.select-field {
  position: relative;
  display: grid;
  grid-template-areas:
    "label"
    "input";
  align-items: start;
  width: 100%;
  isolation: isolate;
}

.select-field select {
  grid-area: input;
  color: var(--text-color-high);
  width: 100%;
  height: 100%;
  padding: .8rem 2rem .8rem 1rem;
  background-color: transparent;
  font-size: 1rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  min-width: 25ch;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  appearance: none;
  transition-property: border-color;
  transition-duration: var(--transition-timing);
}

.select-field > svg {
  grid-area: input;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 1rem;
  fill: var(--text-color-medium);
  transition: fill var(--transition-timing);
  z-index: -1;
}

.select-field select:enabled:hover {
  border-color: var(--text-color-disabled);
}

.select-field select:enabled:hover ~ svg {
  fill: var(--text-color-high);
}

.select-field select:enabled:focus {
  border-color: var(--accent-color-primary);
}

.select-field select:is(:focus) ~ label {
  color: var(--accent-color-primary);
  font-size: .8rem;
}

.select-field select option {
  color: initial;
  padding: 0;
}

.select-field select:is(:disabled) ~ label {
  color: var(--text-color-disabled);
}

.select-field select:disabled {
  color: var(--text-color-disabled);
  cursor: not-allowed;
}

.select-field select:is(:disabled) ~ svg {
  fill: var(--text-color-disabled);
}

.select-field label {
  grid-area: label;
  justify-self: start;
  pointer-events: none;
  color: var(--text-color-medium);
  margin-left: 12px;
  align-self: center;
  transform: translateY(50%);
  background-color: var(--surface-2);
  border-radius: var(--border-radius);
  padding: .2rem .5rem;
  font-size: .8rem;
}
