.input-request-types-container > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.input-request-types-container > .input-request-types {
  margin-left: 1rem;
}

.input-request-types {
  display: flex;
  flex-direction: column;
}

.input-request-types > label > input{
  margin-right: 0.5rem;
}
