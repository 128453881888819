@import "../../sass/_mixins.scss";

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: .5rem;
  background-color: var(--surface-3);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: .25rem .5rem;
  width: 100%;
  animation: var(--animation-fade-in);

  > span {
    word-break: break-all
  }
}

.button {
  @include reset-button;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: .5rem 1rem;
  background-color: var(--surface-1);
  color: var(--text-color-medium);
  font-size: .8rem;
  font-weight: bold;
  word-break: break-word;
  white-space: normal;
  cursor: pointer;
  transition: border .3s, color .3s, background-color .3s;

  &:hover {
    color: var(--color-state-error-medium);
  }
}
