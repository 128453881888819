.wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  row-gap: 4rem;
}

.actions-exams {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
