@import "../../../sass/_mixins.scss";

.wrapper {
  @include reset-button;

  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 1rem;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  list-style: none;
  width: 100%;
  padding: 10px;
  border-radius: var(--border-radius);
  color: var(--text-color-medium);
  transition: background .3s;

  &:hover {
    background-color: var(--surface-1);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-items: center;
  fill: currentColor;

  > svg {
    height: 20px;
  }
}
